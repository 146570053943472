import { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonIcon, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import { ScrollDetail } from '@ionic/core';
import { useTranslation } from 'react-i18next';
import { get, throttle } from 'lodash';

import MainLayout, { MainLayoutRef } from 'src/components/layout/MainLayout';
import MobileMenuHeader from 'src/components/menu/MobileMenuHeader';
import Categories from 'src/components/menu/Categories';
import Products from 'src/components/menu/Products';
import Campaigns from 'src/components/menu/Campaigns';
import MenuCategoriesLoader from 'src/components/loaders/MenuCategoriesLoader';
import MenuProductsLoader from 'src/components/loaders/MenuProductsLoader';
import { useDispatch, useSelector } from 'src/store';
import { useBreakpoint, usePageLanguageReload } from 'src/hooks';
import { openModal } from 'src/slices/salePoint';
import { getCategories, getProducts, openProductDetails, selectCategory, selectProduct } from 'src/slices/product';
import { getStaticPages, selectLanguage } from 'src/slices/system';
import { getCampaigns } from 'src/slices/campaign';
import pizzaPlaceholderImg from 'src/assets/img/pizzakiosk-logo.svg';
import layoutClasses from 'src/styles/layout.module.scss';
import classes from './menuTab.module.scss';
import arrowUpSvg from 'src/assets/img/up-arrow.svg';

interface MenuTabProps extends RouteComponentProps {}

interface IScrollToTopButton {
  scrollToTopFunc: () => void;
}

const ScrollToTopButton: React.FC<IScrollToTopButton> = ({ scrollToTopFunc }) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={scrollToTopFunc} id={classes.scrollToTopButton}>
      <IonIcon src={arrowUpSvg} />
    </div>
  );
};

const MenuTab: React.FC<MenuTabProps> = ({ history, location, match }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [areCategoriesStick, setAreCategoriesStick] = useState<boolean>(false);
  const [initCategoriesOffsetTop, setInitCategoriesOffsetTop] = useState<number>();
  const [prevSelectedCategoryId, setPrevSelectedCategoryId] = useState<number>(null);
  const categoriesRef = useRef<HTMLDivElement | null>(null);
  const layoutRef = useRef<MainLayoutRef | null>(null);
  const { selectedSalePointId, isSalePointPickerOpened } = useSelector((state) => state.salePoint);
  const { categories, products, loading, selectedCategoryId } = useSelector((state) => state.product);
  const staticPages = useSelector((state) => state.system.staticPages);
  const campaigns = useSelector((state) => state.campaign.campaigns);
  const routerProps = { history, location, match };
  const { categories: areCategoriesLoading, products: areProductsLoading } = loading;
  const { isDesktopView } = useBreakpoint();
  const categoryId = Number(get(match, 'params.id'));
  const language = useSelector((state) => state.system.languages).find(
    (lang) => lang.isoName === String(get(match, 'params.lang'))
  );
  const productId = Number(get(match, 'params.productid'));

  useIonViewDidEnter(() => {
    if (selectedSalePointId === null && !isSalePointPickerOpened) {
      dispatch(openModal(true));
    }
  }, [selectedSalePointId, isSalePointPickerOpened]);

  useIonViewWillEnter(() => {
    if (!categories) {
      dispatch(getCategories());
    }
    if (!products) {
      dispatch(getProducts());
    }
    if (!campaigns) {
      dispatch(getCampaigns());
    }
    if (!staticPages) {
      dispatch(getStaticPages());
    }
  }, [categories, products, campaigns, staticPages]);

  const onLanguageChange = () => {
    dispatch(getCategories());
    dispatch(getProducts());
    dispatch(getCampaigns());
    dispatch(getStaticPages());
  };

  usePageLanguageReload({ routerProps, callback: onLanguageChange });

  const onLayoutScroll = (e: CustomEvent<ScrollDetail>) => {
    const categoriesOffsetTop = categoriesRef.current?.offsetTop;
    if (!areCategoriesStick && categoriesOffsetTop !== initCategoriesOffsetTop) {
      setInitCategoriesOffsetTop(categoriesOffsetTop);
    }
    setAreCategoriesStick(e.detail.scrollTop >= initCategoriesOffsetTop);
  };

  const scrollToTopFunc = () => {
    if (!layoutRef.current) return;
    layoutRef.current.scrollToPoint(0, 0, 500);
  };

  useEffect(() => {
    if (selectedCategoryId !== prevSelectedCategoryId) {
      setPrevSelectedCategoryId(selectedCategoryId);
      layoutRef.current.scrollToPoint(0, initCategoriesOffsetTop, 0);
    }
  }, [selectedCategoryId, prevSelectedCategoryId, initCategoriesOffsetTop]);

  useEffect(() => {
    if (categoriesRef && initCategoriesOffsetTop === undefined) {
      const categoriesOffsetTop = categoriesRef.current?.offsetTop;
      setInitCategoriesOffsetTop(categoriesOffsetTop);
    }
  }, [categoriesRef?.current?.offsetTop]);

  useEffect(() => {
    if (categoryId) {
      dispatch(selectCategory(categoryId));
    }
  }, [categoryId, dispatch]);

  useEffect(() => {
      if (language) {
        dispatch(selectLanguage({ languageId: language.id, saveSelection: true }));
      }
  }, [language, dispatch]);

  useEffect(() => {
    if (productId && selectedSalePointId !== null) {
      const productExists = products.some((product) => product.id === productId);
      if (productExists) {
        dispatch(selectProduct(productId));
        dispatch(openProductDetails(true));
      }
    }
  }, [productId, selectedSalePointId, products, dispatch]);

  return (
    <MainLayout
      onScroll={throttle(onLayoutScroll, 100)}
      meta={{
        title: t('-SEO Menu page'),
        description: t('-SEO Menu page description'),
      }}
      {...routerProps}
      ref={layoutRef}
    >
      <div className={layoutClasses.container}>
        {/* Load placeholder before it will be rendered in list. */}
        <img src={pizzaPlaceholderImg} className="ion-hide" alt="placeholderImg" />
        <MobileMenuHeader />
        <Campaigns {...routerProps} />
        {areCategoriesLoading && <MenuCategoriesLoader />}
        {!areCategoriesLoading && !!categories?.length && (
          <Categories ref={categoriesRef} sticky={areCategoriesStick} />
        )}
        {areProductsLoading && <MenuProductsLoader />}
        {!areProductsLoading && !!products?.length && <Products />}
        {isDesktopView && areCategoriesStick && <ScrollToTopButton scrollToTopFunc={scrollToTopFunc} />}
      </div>
    </MainLayout>
  );
};

export default MenuTab;
